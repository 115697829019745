/* noto-serif-sc-200normal - chinese-simplified */
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Noto Serif SC ExtraLight'),
    local('NotoSerifSC-ExtraLight'), 
    url('./files/noto-serif-sc-chinese-simplified-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-serif-sc-chinese-simplified-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-serif-sc-300normal - chinese-simplified */
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Noto Serif SC Light'),
    local('NotoSerifSC-Light'), 
    url('./files/noto-serif-sc-chinese-simplified-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-serif-sc-chinese-simplified-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-serif-sc-400normal - chinese-simplified */
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Noto Serif SC'),
    local('NotoSerifSC-Regular'), 
    url('./files/noto-serif-sc-chinese-simplified-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-serif-sc-chinese-simplified-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-serif-sc-500normal - chinese-simplified */
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Noto Serif SC Medium'),
    local('NotoSerifSC-Medium'), 
    url('./files/noto-serif-sc-chinese-simplified-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-serif-sc-chinese-simplified-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-serif-sc-600normal - chinese-simplified */
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Noto Serif SC SemiBold'),
    local('NotoSerifSC-SemiBold'), 
    url('./files/noto-serif-sc-chinese-simplified-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-serif-sc-chinese-simplified-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-serif-sc-700normal - chinese-simplified */
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Noto Serif SC Bold'),
    local('NotoSerifSC-Bold'), 
    url('./files/noto-serif-sc-chinese-simplified-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-serif-sc-chinese-simplified-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-serif-sc-900normal - chinese-simplified */
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Noto Serif SC Black'),
    local('NotoSerifSC-Black'), 
    url('./files/noto-serif-sc-chinese-simplified-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-serif-sc-chinese-simplified-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
